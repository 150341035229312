import '../../App.css'

import Header from '../Header/Header.js'
import banner from '../Images/banner01.png'
import Contact from '../Contact/Contact.js'
import Planos from '../Planos/Planos.js'
import Footer from '../Footer/Footer.js'

function Home() {
  return (
    <div className="App">
      <Header />
      <header>
        <img
          style={{
            background: 'linear-gradient(to right, #51235C, #1F0A57)',
            margin: '-0.3%'
          }}
          src={banner}
          className="App-banner"
          alt="banner"
        />
      </header>
      <Planos />
      <Contact />
      <Footer />
    </div>
  )
}

export default Home
