import * as React from 'react'
import { Link } from 'react-router-dom'

export default function Privacidade() {
  return (
    <div>
      <p
        style={{
          fontFamily: 'Barlow',
          fontSize: 15,
          textDecoration: 'none',
          margin: 10,
          color: '#1F0A57',
          fontWeight: '600'
        }}
        href="womp.com.br"
      >
        Womp TV e Internet. Todos os direitos reservados &copy;
        {new Date().getFullYear()} &nbsp;&nbsp;&nbsp;{'|'}
        <a
          style={{
            fontFamily: 'Barlow',
            fontSize: 15,
            padding: '1%',
            textDecoration: 'none',
            color: '#1F0A57',
            fontWeight: '800'
          }}
          href="womp.com.br"
        >
          Termos de Uso
        </a>
        <Link to="/privacy">
          <a
            style={{
              fontFamily: 'Barlow',
              fontSize: 15,
              padding: '1%',
              textDecoration: 'none',
              color: '#1F0A57',
              fontWeight: '800'
            }}
            href="womp.com.br"
          >
            Privacidade
          </a>
        </Link>
        <a
          style={{
            fontFamily: 'Barlow',
            fontSize: 15,
            padding: '1%',
            textDecoration: 'none',
            color: '#1F0A57',
            fontWeight: '800'
          }}
          href="womp.com.br"
        >
          Ajuda
        </a>
        <a
          style={{
            fontFamily: 'Barlow',
            fontSize: 15,
            padding: '1%',
            textDecoration: 'none',
            color: '#1F0A57',
            fontWeight: '800'
          }}
          href="womp.com.br"
        >
          Cookies
        </a>
      </p>
    </div>
  )
}
