import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import Home from '../Home/Home.js'
import Privacidade from '../../Pages/Privacidade.js'
import PrivacidadeSeventh from '../../Pages/PrivacidadeSeventh.js'

function Rotas() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacidade />} />
        <Route path="/privacyseventh" element={<PrivacidadeSeventh />} />
      </Routes>
    </Router>
  )
}

export default Rotas
